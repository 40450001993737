import { Typography } from "@mui/material";

export default function ErrorPage(props) {
    return <div>
    <Typography variant="h3">
        Sorry a network error occured
    </Typography>
    <Typography variant="p">
        {props.ErrorText}
    </Typography>
    </div>
}