import { Box,Button, TextField, Typography } from "@mui/material";

export default function Quiz(props) {
    return <Box>
            <Typography variant="h3">
                {props.CurrentQuestion}
            </Typography>
            <TextField
            id="outlined-multiline-static"
            label="Your Answer"
            multiline
            rows={4}
            
            onChange={props.OnAnswerChange}
            />
            <Button onClick={props.OnSubmitAnswer}>Submit Answer</Button>
    </Box>
}