import { Box, Button, Dialog, DialogActions, DialogContent, Fab, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";

export function CreateQuiz(props) {
    return <div>
        <Typography variant="h3">
            Quiz Questions
        </Typography>
        <List>
            {props.ListOfQuestions.map((value,index,array) => <ListItem><ListItemText key={index} primary={"Q" + (index+1) + " " + value}></ListItemText></ListItem> )}
        </List>
        <Dialog open={props.AddDialogOpen}>
            <DialogContent>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Question"
            type="email"
            fullWidth
            variant="standard"
            onChange={props.OnAddDialogTextChanged}
          />
            </DialogContent>
            <DialogActions>
          <Button onClick={props.OnAddCancel}>Cancel</Button>
          <Button onClick={props.OnAddOK}>OK</Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
         <Button onClick={props.OnFabClick}>Add Question</Button>
         <Button onClick={props.OnStartQuizClick}>Start Quiz</Button>
      </Box>
    </div>
}