import { Typography } from "@mui/material";
import { TableContainer, Table, TableHead,TableRow,TableCell,TableBody,Paper,Box } from "@mui/material";

export default function ViewAnswers(props) {
    return (
        <Box>
            <Typography component="h3">
                Quiz Id : {props.AnswersQuizId}
            </Typography>
            <Typography component="h5">
                Here is everyone's answers, this page refreshes automatically every 5 seconds. DO NOT click refesh.
            </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">User</TableCell>
                <TableCell align="right">Question Number</TableCell>
                <TableCell align="right">Answer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.Answers.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.respondentName}</TableCell>
                  <TableCell align="right">{row.questionindex+1}</TableCell>
                  <TableCell align="right">{row.answer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      );
}