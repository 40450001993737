import { AppBar, Button, FormLabel, TextField, useScrollTrigger } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useState } from 'react'; 

export default function StartScreen(props) {
    return <Card variant="outlined">
    <CardContent>
      <Typography variant='p'>
        You can create, mark and answer triva quizzes here.
      </Typography>
    </CardContent>
    <CardActions>
    <TextField
        id="standard-search"
        label="Quiz ID"
        type="search"
        variant="standard"
        onChange={props.OnQuizIdChanged}
      />
      <TextField
        id="standard-search"
        label="Your Name"
        type="search"
        variant="standard"
        onChange={props.OnNameChanged}
      />
      <Button onClick={props.JoinClicked}>Join Quiz</Button>
    <Button onClick={props.CreateClicked}>Create Your Own Quiz</Button>
    </CardActions>
   </Card>
}