import logo from './logo.svg';
import './App.css';
import { AppBar, Button, FormLabel, LinearProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useState } from 'react';
import StartScreen from './startscreen';
import ErrorPage from './ErrorPage';
import { CreateQuiz } from './create';
import ViewAnswers from './ViewAnsers';
import { useEffect } from 'react';
import Quiz from './quiz';

function Content(props) {
  switch (props.Page) {
    case "Start":
      return <StartScreen OnNameChanged={props.OnNameChanged} OnQuizIdChanged={props.OnQuizIdChanged} JoinClicked={props.JoinClicked} CreateClicked={props.CreateClicked} />
    case "Quiz":
      return <Quiz CurrentQuestion={props.CurrentQuestion} OnAnswerChange={props.OnAnswerChange} OnSubmitAnswer={props.OnSubmitAnswer} />
      case "Creating":
      return <CreateQuiz OnStartQuizClick={props.OnStartQuizClick} OnFabClick={props.OnFabClick} OnAddOK={props.OnAddOK} OnAddCancel={props.OnAddCancel} ListOfQuestions={props.ListOfQuestions} OnAddDialogTextChanged={props.OnAddDialogTextChanged} AddDialogOpen={props.AddDialogOpen} />
    case "Answers":
      return <ViewAnswers AnswersQuizId={props.AnswersQuizId} Answers={props.Answers} />
    case "Done":
      return <Card><Typography variant='h2'>You have finished the Quiz.</Typography></Card>
    case "Error":
      return <ErrorPage />
    default:
      <Typography>
        Error
      </Typography>
  }
}

function App() {

  var [appState, setAppState] = useState("Start");
  var [formname, setname] = useState("");
  var [formquizid, setQuizid] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  var [addDialogtext, setAddDialogText] = useState("");
  var [listOfQuestions, setListOfQuestions] = useState([]);
  var [answers, setAnswers] = useState([]);
  var [sessionid, setSessionid] = useState();
  var [creatorQuizId, setCreatorQuizid] = useState("");
  var [quizAnswer,setQuizAnswer] = useState("");
  var [currentQuestionIndex,setCurrentQuestionIndex] = useState(0);
  
  useEffect( () => {
     
     if (appState === "Quiz") {
      fetch("/api/questions",
              {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                redirect: "follow", // manual, *follow, error
                headers: {
                  "Cgnquiz-Session": sessionStorage.getItem("cgn-sid")
                },
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              }).then((responce) => {
                // setSessionid(responce.headers.get("Cgnquiz-Session"));
                if (responce.status === 200) {
                  responce.json().then((data) => {
                    setIsLoading(false);
                    setListOfQuestions(data.questions);
                  });
                }
              });
     }
  },[appState]);

  function SubmitAnswerClicked() {
    fetch("/api/answer", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
        "Cgnquiz-Session": sessionStorage.getItem("cgn-sid")
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        questionIndex: currentQuestionIndex,
        answer : quizAnswer
      })}).then( (reponse) => {
                setCurrentQuestionIndex(currentQuestionIndex + 1); 
              if (currentQuestionIndex+1 === listOfQuestions.length) {
                setAppState("Done");
              }
         });
       // body data type must match "Content-Type" header
  }

  function JoinClicked() {
    console.log("Quiz id = " + formquizid)
    console.log("JoinClicked()");
    setIsLoading(true);
    fetch("/api/name", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        quizid: formquizid,
        name: formname
      }), // body data type must match "Content-Type" header
    }).then((responce) => {
      if (responce.headers.has("Cgnquiz-Session")) {
        console.log("has session header ");
        sessionStorage.setItem("cgn-sid",responce.headers.get("Cgnquiz-Session"));
      }
      if (responce.status === 200) {setIsLoading(false); setAppState("Quiz");} else setAppState("Error");
    }).catch(() => setAppState("Error"));
  }

  function CreateClicked(name) {
    setIsLoading(true);
    fetch("/api/name", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        quizid: formquizid,
        name: formname
      }), // body data type must match "Content-Type" header
    }).then((responce) => {
      if (responce.headers.has("Cgnquiz-Session")) {
        console.log("has session header ");
        sessionStorage.setItem("cgn-sid",responce.headers.get("Cgnquiz-Session"));
      }
      if (responce.status === 200) {setIsLoading(false); setAppState("Creating");;} else setAppState("Error");
    }).catch(() => setAppState("Error"));
    
  }

  function StartQuizClick() {
    setIsLoading(true);
    fetch("/api/create", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
         
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        name: formname,
        questions: listOfQuestions
      }) // body data type must match "Content-Type" header
    }).then((responce) => {
      responce.headers.forEach((value, key) => console.log("header:", key, 'value:', value));
      if (responce.headers.has("Cgnquiz-Session")) {
        console.log("has session header ");
        sessionStorage.setItem("cgn-sid",responce.headers.get("Cgnquiz-Session"));
      }
      if (responce.status === 200) {
        responce.json().then((data) => { setCreatorQuizid(data.quizid);
        setIsLoading(false);
        setAppState("Answers");
      }); 
    }});
  }
  
  
      useEffect(() => {

        
          
           setInterval(() => {
            if (appState === "Answers" && sessionStorage.getItem("cgn-sid") != null) {
            setIsLoading(true);

            fetch("/api/answers",
              {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                redirect: "follow", // manual, *follow, error
                headers: {
                  "Cgnquiz-Session": sessionStorage.getItem("cgn-sid")
                },
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              }).then((responce) => {
                // setSessionid(responce.headers.get("Cgnquiz-Session"));
                if (responce.status === 200) {
                  responce.json().then((data) => {
                    setIsLoading(false);
                    setAnswers(data);
                  });
                }
              });
            }
          },5000);
          return () => { clearTimeout()}
        },[appState,sessionid]);

      return (
        <div className="App">
          <AppBar position="static" >
            <Typography variant="h1">
              CGN Quiz
            </Typography>
          </AppBar>
          <Content
            CurrentQuestion={"Q" + currentQuestionIndex+1 + " " + listOfQuestions[currentQuestionIndex]}
            OnAnswerChange={(e) => setQuizAnswer(e.target.value)}
            OnSubmitAnswer={SubmitAnswerClicked}
            AnswersQuizId={creatorQuizId}
            Answers={answers}
            OnStartQuizClick={StartQuizClick}
            ListOfQuestions={listOfQuestions}
            OnFabClick={() => setIsCreateDialogOpen(true)}
            OnAddOK={() => {
              console.log("OnAddOk()")
              setListOfQuestions( // Replace the state
                [ // with a new array
                  ...listOfQuestions, // that contains all the old items
                  addDialogtext // and one new item at the end
                ]);
              setIsCreateDialogOpen(false);

            }}
            OnAddCancel={() => {
              setIsCreateDialogOpen(false);
            }
            }
            OnAddDialogTextChanged={(e) => {
              setAddDialogText(e.target.value);

            }}
            AddDialogOpen={isCreateDialogOpen} NameChanged={(e) => setname(e.target.value)}
            OnQuizIdChanged={(e) => { console.log("OnQuizidchanged"); setQuizid(e.target.value)}}
            OnNameChanged={(e) => setname(e.target.value)}
            JoinClicked={JoinClicked}
            CreateClicked={CreateClicked}
            Page={appState} />
          {isLoading !== false ? <LinearProgress>
          </LinearProgress> : null}
        </div>
      );
    }

export default App;
